import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';

export const showProduct = createAsyncThunk('product/showProduct', async (thunkAPI) => {
    const response = await axiosInstance.get('/Product', {
        params: {
            $expand: 'Services'
        }
    });

    return response.data.value;
});

export const addProduct = createAsyncThunk('product/addProduct', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/Product', data);
    thunkAPI.dispatch(showProduct());
    return response.data;
});

export const removeProduct = createAsyncThunk('product/removeProduct', async (data, thunkAPI) => {
    const response = await axiosInstance.delete('/Product', data);
    thunkAPI.dispatch(showProduct());
    return response.data;
});

export const updateProduct = createAsyncThunk('product/updateProduct', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/Product', data);
    thunkAPI.dispatch(showProduct());
    return response.data;
});

const initialState = {
    product: [],
    loading: false
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showProduct.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showProduct.fulfilled, (state, action) => {
            state.product = action.payload;
            state.loading = false;
        });
        builder.addCase(showProduct.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseProduct = (state) => state.product;

export default productSlice.reducer;
