import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';

export const showUser = createAsyncThunk('user/showUser', async (thunkAPI) => {
    const response = await axiosInstance.get('/User', {
        type: 'get_menu'
    });

    return response.data.value;
});

export const addUser = createAsyncThunk('user/addUser', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/User', data);
    thunkAPI.dispatch(showUser());
    return response.data;
});

export const removeUser = createAsyncThunk('user/removeUser', async (data, thunkAPI) => {
    const response = await axiosInstance.delete('/User', data);
    thunkAPI.dispatch(showUser());
    return response.data;
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/User', data);
    thunkAPI.dispatch(showUser());
    return response.data;
});

const initialState = {
    user: [],
    loading: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.loading = false;
        });
        builder.addCase(showUser.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseUser = (state) => state.user;

export default userSlice.reducer;
