// assets
import { IconUsers, IconKey } from '@tabler/icons';

// constant
const icons = { IconUsers, IconKey };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const permission = {
    id: 'perrmission',
    type: 'group',
    title: 'Permission',
    children: [
        {
            id: 'permission-user',
            title: 'User',
            type: 'item',
            url: '/permission/user',
            icon: icons.IconUsers
        },
        {
            id: 'permission-role',
            title: 'Role',
            type: 'item',
            url: '/permission/role',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'permission-group',
            title: 'Group',
            type: 'item',
            url: '/permission/group',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'permission-category',
            title: 'Category',
            type: 'item',
            url: '/permission/category',
            icon: icons.IconKey,
            breadcrumbs: true
        },
        {
            id: 'permission-application',
            title: 'Application',
            type: 'item',
            url: '/permission/application',
            icon: icons.IconKey,
            breadcrumbs: true
        }
    ]
};

export default permission;
