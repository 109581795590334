import dashboard from './dashboard';
import pages from './pages';
import finances from './finances';
import permission from './permission';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, finances, permission]
};

export default menuItems;
