import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { roleReducer, userReducer } from './permission';
import { productReducer } from './management';
import { transactionReducer, bucketReducer, categoryReducer } from './finance';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user: userReducer,
    role: roleReducer,
    product: productReducer,
    bucket: bucketReducer,
    category: categoryReducer,
    transaction: transactionReducer
});

export default reducer;
