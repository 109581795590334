import axios from 'axios';

const axiosInstance = axios.create({
    // Add your custom Axios configuration here
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 5000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token')
    },
    transformRequest: [
        (data) => {
            // Customize request data or headers if needed
            return JSON.stringify(data);
        }
    ]
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + window.localStorage.getItem('token')
    };

    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        // Customize response data if needed
        return response;
    },
    (error) => {
        // Customize error data if needed
        return Promise.reject(error);
    }
);

export { axiosInstance };
