import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';

export const showBucket = createAsyncThunk('bucket/showBucket', async (thunkAPI) => {
    const response = await axiosInstance.get('/Bucket', {
        params: {
            $expand: 'Transactions'
        }
    });

    return response.data.value;
});

export const addBucket = createAsyncThunk('bucket/addBucket', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/Bucket', data);
    thunkAPI.dispatch(showBucket());
    return response.data;
});

export const updateBucket = createAsyncThunk('bucket/updateBucket', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/Bucket', data);
    thunkAPI.dispatch(showBucket());
    return response.data;
});

const initialState = {
    bucket: [],
    loading: false
};

const bucketSlice = createSlice({
    name: 'bucket',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showBucket.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showBucket.fulfilled, (state, action) => {
            state.bucket = action.payload;
            state.loading = false;
        });
        builder.addCase(showBucket.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseBucket = (state) => state.bucket;

export default bucketSlice.reducer;
