// assets
import { IconFileInvoice, IconCash } from '@tabler/icons';

// constant
const icons = {
    IconFileInvoice,
    IconCash
};

// ==============================|| services MENU ITEMS ||============================== //

const finances = {
    id: 'finance',
    title: 'Finance',
    type: 'group',
    children: [
        {
            id: 'transaction-history',
            title: 'Lịch sử giao dịch',
            type: 'item',
            url: '/finance/transaction-history',
            icon: icons.IconCash
        },
        {
            id: 'bucket',
            title: 'Ví',
            type: 'item',
            url: '/finance/bucket',
            icon: icons.IconCash
        },

        {
            id: 'category',
            title: 'Thể loại',
            type: 'item',
            url: '/finance/category',
            icon: icons.IconCash
        }
    ]
};

export default finances;
