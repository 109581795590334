// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
import logo from 'assets/images/logo.jpeg';
const Logo = () => {
    return <img src={logo} alt="UOSVN" width="50" height="50" />;
};

export default Logo;
