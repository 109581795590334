import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const PermissionRole = Loadable(lazy(() => import('views/permission/role/Role')));
const PermissionUser = Loadable(lazy(() => import('views/permission/user/User')));
const TransactionHistory = Loadable(lazy(() => import('views/pages/finance/transaction/Transaction')));
const Bucket = Loadable(lazy(() => import('views/pages/finance/bucket/Bucket')));
const Category = Loadable(lazy(() => import('views/pages/finance/category/Category')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/permission/role',
            element: <PermissionRole />
        },
        {
            path: '/permission/user',
            element: <PermissionUser />
        },
        {
            path: '/finance/transaction-history',
            element: <TransactionHistory />
        },
        {
            path: '/finance/bucket',
            element: <Bucket />
        },
        {
            path: '/finance/category',
            element: <Category />
        }
    ]
};

export default MainRoutes;
