import { configureStore } from '@reduxjs/toolkit';

import logger from 'redux-logger';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(logger)
});
const persister = 'Free';

export { store, persister };
