import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';

export const showRole = createAsyncThunk('role/showRole', async (thunkAPI) => {
    const response = await axiosInstance.get('/Role', {
        type: 'get_menu'
    });

    return response.data.value;
});

export const addRole = createAsyncThunk('role/addRole', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/Role', data);
    thunkAPI.dispatch(showRole());
    return response.data;
});

export const removeRole = createAsyncThunk('role/removeRole', async (data, thunkAPI) => {
    const response = await axiosInstance.delete('/Role', data);
    thunkAPI.dispatch(showRole());
    return response.data;
});

export const updateRole = createAsyncThunk('role/updateRole', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/Role', data);
    thunkAPI.dispatch(showRole());
    return response.data;
});

const initialState = {
    role: [],
    loading: false
};

const roleSlice = createSlice({
    name: 'role',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showRole.fulfilled, (state, action) => {
            state.role = action.payload;
            state.loading = false;
        });
        builder.addCase(showRole.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseRole = (state) => state.role;

export default roleSlice.reducer;
