import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';
import { showBucket } from 'store/finance/bucket';

export const showTransaction = createAsyncThunk('transaction/showTransaction', async (thunkAPI) => {
    const response = await axiosInstance.get('/Transaction', {
        params: {
            $expand: 'User,Category,Bucket'
        }
    });

    return response.data.value;
});

export const addTransaction = createAsyncThunk('transaction/addTransaction', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/Transaction', data);
    thunkAPI.dispatch(showBucket());
    return response.data;
});

export const updateTransaction = createAsyncThunk('transaction/updateTransaction', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/Transaction', data);
    thunkAPI.dispatch(showTransaction());
    return response.data;
});

const initialState = {
    transaction: [],
    loading: false
};

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showTransaction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showTransaction.fulfilled, (state, action) => {
            state.transaction = action.payload;
            state.loading = false;
        });
        builder.addCase(showTransaction.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseTransaction = (state) => state.transaction;

export default transactionSlice.reducer;
