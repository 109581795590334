import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from 'utils/axios';

export const showCategory = createAsyncThunk('category/showCategory', async (thunkAPI) => {
    const response = await axiosInstance.get('/Category', {
        params: {
            $expand: 'Transactions'
        }
    });

    return response.data.value;
});

export const addCategory = createAsyncThunk('category/addCategory', async (data, thunkAPI) => {
    const response = await axiosInstance.post('/Category', data);
    thunkAPI.dispatch(showCategory());
    return response.data;
});

export const updateCategory = createAsyncThunk('category/updateCategory', async (data, thunkAPI) => {
    const response = await axiosInstance.put('/Category', data);
    thunkAPI.dispatch(showCategory());
    return response.data;
});

const initialState = {
    category: [],
    loading: false
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(showCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showCategory.fulfilled, (state, action) => {
            state.category = action.payload;
            state.loading = false;
        });
        builder.addCase(showCategory.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const responseCategory = (state) => state.category;

export default categorySlice.reducer;
