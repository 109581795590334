import { useSelector } from 'react-redux';
import { lazy } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';
import Loadable from 'ui-component/Loadable';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
import { AuthProvider, useAuth } from 'contexts/auth';

// ==============================|| APP ||============================== //
function Root() {
    const { user, loading } = useAuth();

    // if (loading) {
    //     return <LoadPanel visible={true} />;
    // }

    if (user) {
        return <Routes />;
    }

    return <AuthLogin3 />;
}
const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <AuthProvider>
                    <CssBaseline />
                    <NavigationScroll>
                        <Root />
                    </NavigationScroll>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
